<template>
  <window-default ref="window" :resolve="find" :proccessData="proccessData" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome:"
          >
            <erp-input autofocus v-model="model.nome" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Código Único:"
          >
            <erp-input autofocus v-model="model.codigo" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descrição:"
          >
            <erp-input v-model="model.descricao" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Gera Comissão?"
          >
            <erp-checkbox v-model="model.comissao" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Valor da Comissão:"
          >
            <erp-input v-model="model.valorComissao" size="2" v-money="money" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Dia do Lançamento:"
          >
            <erp-input v-model="model.diaLancamento" size="2" type="number" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {
  find,
  newProdutividadeTipoEtapa as _new,
  updateProdutividadeTipoEtapa as update,
} from '../../../../../domain/processos/services/produtividadeTipoEtapa'
import save from '../../../helpers/save'
import {convertRealToMoney, REAL_BRL} from "@/utils/money";
import {datePtToEn} from "@/utils/date";
import {VMoney} from "v-money";

let mock = {
  id: null,
  nome: null,
  codigo: null,
  descricao: null,
  comissao: false,
  valorComissao: 0,
  diaLancamento: null,
  active: true
}

export default {
  name: 'Window',
  components: {ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox},
  props: ['props'],
  directives: {money: VMoney},
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      money: REAL_BRL
    }
  },
  mounted () {
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    beforeSave (originalData) {
      let data = JSON.parse(JSON.stringify(originalData))
      const valores = ['valorComissao']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key], true)
        if (!data[key]) {
          data[key] = null
        }
      })
      return data
    },
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)

      if (data.tipo && data.tipo.id) {
        data.tipo = data.tipo.id
      }
      return data
    }
  }
}
</script>
