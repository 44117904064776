import http from '../../../utils/services/http'

export const newProdutividadeTipoEtapa = () => {
  let url = `/api/processos/produtividadeTipoEtapa`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/processos/produtividadeTipoEtapa/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateProdutividadeTipoEtapa = (id, data) => {
  let url = `/api/processos/produtividadeTipoEtapa/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
